import React, { useState, useEffect } from "react"
import styled from "styled-components"
import scrollTo from "gatsby-plugin-smoothscroll"
import useIsDarkTheme from "hooks/useIsDarkTheme"
import ArrowDark from "images/arrow-up-dark.svg"
import ArrowLight from "images/arrow-up-light.svg"
import { sizes } from "styles"

const StyledScrollUpButton = styled.div`
  position: fixed;
  bottom: 4px;
  right: 4px;
  width: 65px;
  height: 65px;

  @media ${sizes.smallDesktop} {
    display: none;
  }
`

const DummyTopElement = styled.div`
  position: absolute;
  top: 0;
`

const ScrollUpButton = () => {
  const isDarkTheme = useIsDarkTheme()
  const [showButton, setShowButton] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100 && !showButton) {
        setShowButton(true)
      } else if (window.scrollY < 100 && showButton) {
        setShowButton(false)
      }
    }

    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [showButton])

  return (
    <>
      <DummyTopElement id="top" />
      {showButton && (
        <StyledScrollUpButton onClick={() => scrollTo("#top")}>
          {isDarkTheme ? (
            <img src={ArrowDark} alt="arrow up" />
          ) : (
            <img src={ArrowLight} alt="arrow up" />
          )}
        </StyledScrollUpButton>
      )}
    </>
  )
}

export default ScrollUpButton
