import React from "react"
import { BLOCKS, INLINES } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import styled from "styled-components"
import Img from "gatsby-image"
import { th, sizes } from "styles"

const ImageContainer = styled.div`
  position: relative;
  margin-bottom: 6px;
  width: calc(100% - 6px);

  @media ${sizes.smallDesktop} {
    margin-bottom: 44px;
    width: calc(100% - 12px);
  }

  :not(:first-child) {
    margin-top: 28px;
  }

  ::before {
    content: " ";
    position: absolute;
    bottom: -6px;
    right: -6px;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    background: linear-gradient(${th("color.yellow")}, ${th("color.pink")});

    @media ${sizes.smallDesktop} {
      bottom: -12px;
      right: -12px;
    }
  }
`

const StyledImage = styled(Img)`
  width: 100%;
  border-radius: 6px;
`

const StyledText = styled.div`
  font-weight: 500;
  white-space: pre-line;
  line-height: 26px;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;

  :empty,
  > b:empty {
    line-height: 12px;
  }

  :empty:before,
  > b:empty:before {
    content: " ";
    white-space: pre;
  }
`

const StyledH2 = styled.h2`
  position: relative;
  font-size: 24px;
  line-height: 30px;
  margin-top: 40px;
  padding-left: 28px;

  @media ${sizes.tabletLandscape} {
    font-size: 30px;
    line-height: 32px;
  }

  :first-child {
    margin-top: 0;
  }

  ::before {
    position: absolute;
    top: 8px;
    left: 0;
    content: " ";
    width: 16px;
    height: 16px;
    border-radius: 2px;
    transform: rotate(45deg);
    background-color: ${th("color.green")};
  }

  :nth-of-type(4n + 1) {
    ::before {
      background-color: ${th("color.blue")};
    }
  }

  :nth-of-type(4n + 2) {
    ::before {
      background-color: ${th("color.pink")};
    }
  }

  :nth-of-type(4n + 4) {
    ::before {
      background-color: ${th("color.yellow")};
    }
  }

  @media ${sizes.desktop} {
    margin-top: 50px;
  }
`

const StyledH3 = styled.h3`
  margin-top: 30px;
`

const BlockQuote = styled.blockquote`
  position: relative;
  margin-left: 24px;
  margin-right: 0;

  > div {
    font-style: italic;
    font-weight: 300;
    font-size: 20px;
    line-height: 30px;
  }

  ::before {
    content: "“";
    position: absolute;
    top: -8px;
    left: -20px;
    font-family: ${th("font.headings")};
    font-size: 40px;
  }
`

export const richTextOptions = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <StyledText>{children}</StyledText>,
    [BLOCKS.HEADING_1]: (node, children) => <StyledH2>{children}</StyledH2>,
    [BLOCKS.HEADING_2]: (node, children) => <StyledH2>{children}</StyledH2>,
    [BLOCKS.HEADING_3]: (node, children) => <StyledH3>{children}</StyledH3>,
    [BLOCKS.QUOTE]: (node, children) => <BlockQuote>{children}</BlockQuote>,
    [BLOCKS.EMBEDDED_ASSET]: node => {
      if (node.data?.target?.fluid)
        return (
          <ImageContainer>
            <StyledImage
              fluid={node.data.target.fluid}
              alt={node.data.target.title}
            />
          </ImageContainer>
        )
      return null
    },
    [INLINES.HYPERLINK]: node => {
      return (
        <a href={node.data.uri} target="_blank" rel="noopener noreferrer">
          {node.content[0].value}
        </a>
      )
    },
  },
}

// TODO add rich text image rendering with gatsby-image
export default ({ richText }) => renderRichText(richText, richTextOptions)
