import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import format from "date-fns/format"
import { HiArrowNarrowLeft, HiArrowNarrowRight } from "react-icons/hi"
import TransitionLink from "components/TransitionLink"
import PageAnimator from "components/PageAnimator"
import ScrollUpButton from "components/ScrollUpButton"
import SEO from "components/Seo"
import RichTextRenderer from "components/RichTextRenderer"
import { th, sizes } from "styles"

const BlogPostPage = styled(PageAnimator)`
  padding: 20px;
  padding-top: 100px;
  padding-bottom: 80px;
  max-width: 750px;
  margin: 0 auto;

  @media ${sizes.tabletPortrait} {
    padding: 40px;
    padding-top: 90px;
    padding-bottom: 80px;
  }

  @media ${sizes.desktop} {
    max-width: 800px;
  }
`

const Links = styled.div`
  display: none;

  @media ${sizes.smallDesktop} {
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 94%;
    max-width: 1200px;
    display: block;
  }
`

const MobileLinks = styled.div`
  margin-top: 20px;

  @media ${sizes.smallDesktop} {
    display: none;
  }
`

const PrevLink = styled(TransitionLink)`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: ${th("textColor")};

  :hover {
    color: ${th("color.blue")};
  }

  > svg {
    vertical-align: middle;
    margin-left: 4px;
  }

  @media ${sizes.smallDesktop} {
    position: absolute;
    top: 95px;
    right: 0;
  }
`

const NextLink = styled(TransitionLink)`
  flex: 1;
  align-items: center;
  display: flex;
  color: ${th("textColor")};

  :hover {
    color: ${th("color.blue")};
  }

  > svg {
    vertical-align: middle;
    margin-right: 4px;
  }

  @media ${sizes.smallDesktop} {
    position: absolute;
    top: 95px;
    left: 0;
  }
`

const ImageContainer = styled.div`
  position: absolute;
  top: -90px;
  right: 14px;
  width: 110px;
  transform: rotate(5deg);

  > .gatsby-image-wrapper {
    border-radius: 6px;
  }

  ::after {
    content: " ";
    position: absolute;
    bottom: -6px;
    right: -6px;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    background: linear-gradient(${th("color.green")}, ${th("color.blue")});
    z-index: -1;
  }

  @media ${sizes.smallDesktop} {
    top: 0;
    right: 15px;
    width: 280px;
    transform: rotate(10deg);

    ::after {
      bottom: -12px;
      right: -12px;
    }
  }
`

const Rectangle = styled.div`
  position: absolute;
  top: -45px;
  left: 20px;
  width: 55px;
  height: 55px;
  border-radius: 4px;
  transform: rotate(-15deg);
  background: linear-gradient(${th("color.yellow")}, ${th("color.pink")});

  @media ${sizes.smallDesktop} {
    display: none;
  }
`

const Circle = styled.div`
  position: absolute;
  top: -95px;
  left: 30%;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background: radial-gradient(${th("color.white")}, ${th("color.nude")});

  @media ${sizes.smallDesktop} {
    display: none;
  }
`

const Post = styled.div`
  position: relative;
  padding: 20px;
  border: 2px solid ${th("textColor")};
  border-radius: 10px;
  background-color: ${th("backgroundColor")};

  @media ${sizes.tabletPortrait} {
    padding: 40px;
  }
`

const PostTitle = styled.h1`
  margin-top: 12px;

  @media ${sizes.smallDesktop} {
    margin-right: 300px;
  }
`

const Info = styled.div`
  font-size: 12px;
  margin-bottom: 32px;

  @media ${sizes.smallDesktop} {
    margin-bottom: 48px;
  }
`

const BlogPost = ({ data, pageContext }) => {
  const { contentfulBlogPost } = data
  const {
    title,
    subtitle,
    featuredImage,
    metaImage,
    metaDescription,
    publishedOn,
    content,
    authors,
  } = contentfulBlogPost
  const { nextPageLink, prevPageLink } = pageContext

  return (
    <>
      <Links>
        {nextPageLink && (
          <NextLink to={nextPageLink}>
            <HiArrowNarrowLeft /> Newer
          </NextLink>
        )}
        {prevPageLink && (
          <PrevLink to={prevPageLink}>
            Older <HiArrowNarrowRight />
          </PrevLink>
        )}
      </Links>
      <BlogPostPage>
        <Post>
          <Rectangle />
          <Circle />
          <ImageContainer>
            <Img
              fluid={{ ...featuredImage.fluid, aspectRatio: 1 / 1 }}
              alt={featuredImage.title}
            />
          </ImageContainer>
          <PostTitle>{title}</PostTitle>
          <h3>{subtitle}</h3>
          <Info>{`by ${authors} | ${format(
            new Date(publishedOn),
            "MMM d, yyyy"
          )}`}</Info>
          <RichTextRenderer richText={content} />
        </Post>
        <MobileLinks>
          {nextPageLink && (
            <NextLink to={nextPageLink}>
              <HiArrowNarrowLeft /> Newer
            </NextLink>
          )}
          {prevPageLink && (
            <PrevLink to={prevPageLink}>
              Older <HiArrowNarrowRight />
            </PrevLink>
          )}
        </MobileLinks>
      </BlogPostPage>
      <ScrollUpButton />
      <SEO
        title={title}
        image={
          metaImage
            ? {
                src: metaImage.resize.src,
                width: 1600,
                height: 900,
              }
            : undefined
        }
        description={
          metaDescription
            ? metaDescription.metaDescription.substr(0, 157) + "..."
            : undefined
        }
      />
    </>
  )
}

export const pageQuery = graphql`
  query blogPostQuery($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      subtitle
      slug
      featuredImage {
        fluid(maxWidth: 1000) {
          ...GatsbyContentfulFluid
        }
      }
      publishedOn
      authors
      content {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            __typename
            title
            fluid(maxWidth: 1000) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
      metaImage {
        resize(width: 1600, height: 900) {
          src
        }
      }
      metaDescription {
        metaDescription
      }
    }
  }
`

export default BlogPost
